import React from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { createGlobalStyle } from "styled-components"
import { Link } from 'gatsby';
import Background from '../../../images/login.png';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { LoginAction } from '../../../redux/actions/auth';

const GlobalStyle = createGlobalStyle`
    main {
        padding: 0 !important;
    }
`
const ContainerGeneral = styled.section`
    button{
        border-radius: 10;
        background-color: #1D2146 !important;
        border-color: #1D2146 !important;
        padding:10px 50px;
        cursor:pointer;
        z-index: 999 !important;
    }
    .login .form-control {
        border-radius: 20px;
    }

    .login .form-control:focus {
        background-color: #FFFFFF;
    }

    .login ::placeholder {
        color: #C4C4C4;
        opacity: 1;
    }

    .login :-ms-input-placeholder {
        color: #C4C4C4;
    }

    .login ::-ms-input-placeholder {
        color: #C4C4C4;
    }
`
const Title = styled.h1`
    font-size: 3rem;
    color: #2E2A48;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`
const H2 = styled.h2`
    font-family: Garamond;
    font-size: 1rem;
    color: #2E2A48;
`
const ContainerForm = styled.div`
    max-width: 500px;
    margin: auto;
`


const Main = () => {

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const LoginFn = (body) => dispatch(LoginAction(body));




    const ContainerBg = styled(Col)`
        @media screen and (min-width: 992px) {
            .bg_register {
                background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${Background});
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 100vh;
            }
        }
    `

    const [validated, setValidated] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        formData()

    };

    const [formdata, setFormData] = React.useState({
        password: '',
        email: '',
    })

    const { password, email } = formdata;

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const formData = () => {
        // console.log(formdata)
        
        LoginFn(formdata)
    }


    return (
        <ContainerGeneral>
            <GlobalStyle />
            <Container fluid className="bg_white w-100">
                <Row className="d-lg-flex align-items-center justify-content-center login">
                    <Col xs={12} md={7} lg={5} xl={6} className="justify-content-center pt-5 pt-lg-0">
                        <ContainerForm>
                            <StaticImage src="../../../images/villanautica-logo.png" width={150} height={220} alt="Logo" className="" />
                            <Title>¡Bienvenido!</Title>
                            <H2>Introduce tus datos para iniciar sesión</H2>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            required
                                            name="email"
                                            onChange={readFormData}
                                            style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Email requerido
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                        <Form.Label>Contraseña</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Contraseña"
                                            required
                                            name="password"
                                            onChange={readFormData}
                                            style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Contraseña requerida
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <button type="submit" className="btn btn-primary mt-4 w-100">Acceder</button>
                            </Form>
                        </ContainerForm>
                    </Col>
                    <ContainerBg xs={12} md={10} lg={7} xl={6} className="px-0 py-4 py-lg-0 text-center">
                        <div className="bg_register"></div>
                    </ContainerBg>
                </Row>
            </Container>
        </ContainerGeneral>
    );
}

export default Main;