import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
} from './actionsType';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';
import { navigate } from 'gatsby';


export function LoginAction(formdata) {
    return (dispatch) => {
        dispatch(AuthStart())
        clienteAxios.post('/api/users/signin', formdata)
            .then((res) => {
                const UserData = res.data;
                // console.log(UserData)
                dispatch(AuthSuccess(UserData))
                if (UserData.user.genericPassword) {
                    navigate('/dashboard/usuario/cambio-de-clave')
                } else {
                    if(UserData.user.role === 'superAdmin'){
                        navigate('/dashboard/clientes')
                    }else{
                        navigate('/dashboard/usuario/cuotas')
                    }
                }
            })
            .catch(error => {
                dispatch(AuthFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Usuario o contraseña incorrecta'
                    })
                }
                else if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Compañia no activa'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error, intente de nuevo'
                    })
                }
            })
    }
}
export const AuthStart = () => {
    return {
        type: AUTH_START
    };
};

export const AuthSuccess = (auth) => ({
    type: AUTH_SUCCESS,
    payload: auth
})

export const AuthFail = () => ({
    type: AUTH_FAIL,
})