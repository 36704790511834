
import React from 'react';
import SEO from '../structure/seo';
import Layout from '../structure/layout';
import Main from '../structure/pages/login/main';

const Login = () => {
	return (
		<Layout shadowNav={true} noFooter noHeader>
			<SEO title={'Inicio'} />
			<Main />
		</Layout>
	);
}

export default Login;